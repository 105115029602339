import type { QueryResult } from '@apollo/client'
import { useLazyQuery } from '@apollo/client'
import type { ReactNode } from 'react'
import { useCallback, createContext, useContext, useEffect, useState } from 'react'
import { apolloClient, CurrentUserProvider, identifyUser as sentryIdentify, gtmCall } from '@qasa/app'
import { ampli } from '@qasa/ampli/p2'
import { useQueryState } from 'nuqs'
import type { Exact, MeQueryQuery } from '@qasa/graphql'

import { BRAND_TOGGLES } from '../config/toggles'
import {
  getAdminToken,
  getNativeAccessToken,
  getP1AccessToken,
  setAdminToken,
  setP1AccessToken,
} from '../helpers/p1-auth'
import { logoutAll } from '../vendor/schibsted-identity-client'
import { addUnauthenticatedInterceptor, removeUnauthenticatedInterceptor } from '../helpers/p1-api'
import { ME } from '../data/graphql/queries'
import { useEffectOnMount } from '../hooks/use-effect-on-mount'
import { useExchangeSignInCode } from '../ui-page-modules/authentication-flow/otp-login/api/use-exchange-sign-in-code'

export type AuthBodyType = NonNullable<MeQueryQuery['me']> | undefined

export type AuthContextType = {
  authBody: AuthBodyType
  hasRedirectedViaLogout: boolean
  isAuthenticated: boolean
  isLoading: boolean
  logout: ({ shouldRedirectToFrontPage }?: { shouldRedirectToFrontPage?: boolean }) => void
  refreshAuthBody: () => Promise<QueryResult<MeQueryQuery, Exact<Record<string, never>>> | null>
  setAccessTokenAndFetchUser: (accessToken: string) => Promise<unknown>
}

// @ts-ignore
const AuthContext = createContext<AuthContextType>()

function AuthProvider({ children }: { children: ReactNode }) {
  const [authBody, setAuthBody] = useState<AuthBodyType>()
  const [fetchLoggedUser, { data: queryData, error }] = useLazyQuery(ME)
  const { exchangeSignInCode } = useExchangeSignInCode()
  // This has to be manually synced in `client-app` so that React Router
  // will pick up when the `sign_in_code` query param changes.
  // Se `client-app.tsx`
  const [signInCode, setSignInCode] = useQueryState('sign_in_code')
  const hasAccessToken = Boolean(getP1AccessToken())

  const hasAuthToken = getAdminToken() || getP1AccessToken()
  const isAuthenticated = Boolean(hasAuthToken) && Boolean(authBody?.uid)

  const isNativeApp = typeof window !== 'undefined' && window.isNativeApp
  const nativeAccessToken = getNativeAccessToken()
  const hasSignInCode = Boolean(signInCode)
  const [isInitializing, setInitializing] = useState((hasAccessToken || hasSignInCode) && !authBody)
  const [hasRedirectedViaLogout, setHasRedirectedViaLogout] = useState(false)

  const logout = useCallback(
    ({ shouldRedirectToFrontPage = true }: { shouldRedirectToFrontPage?: boolean } = {}) => {
      setAdminToken()
      setP1AccessToken()

      /**
       * Skip calling Schibsted logout methods unless the user is actually authenticated when calling `logout`,
       * since otherwise there will be a redirect to /auth_callback which makes no sense
       */
      if (
        BRAND_TOGGLES.availableLoginMethods.some((method) =>
          ['schibsted_se', 'schibsted_fi'].includes(method),
        ) &&
        isAuthenticated
      ) {
        logoutAll()
      }

      setAuthBody(undefined)
      apolloClient.resetStore()
      setInitializing(false)
      if (shouldRedirectToFrontPage) {
        window.location.href = '/'
        setHasRedirectedViaLogout(true)
        setTimeout(() => {
          setHasRedirectedViaLogout(false)
        }, 10000)
      }
    },
    [setAuthBody, isAuthenticated],
  )

  useEffect(() => {
    /* Add an axios interceptor to trigger logout when there is a 401 response */
    addUnauthenticatedInterceptor({ onUnauthenticated: () => logout({ shouldRedirectToFrontPage: false }) })
    return () => {
      removeUnauthenticatedInterceptor()
    }
  }, [logout])

  useEffect(() => {
    // If in an authenticated native app, use the native access token
    if (isNativeApp && Boolean(nativeAccessToken)) {
      setP1AccessToken(nativeAccessToken)
    }
  }, [isNativeApp, nativeAccessToken])

  useEffectOnMount(() => {
    if (hasAccessToken) {
      if (isNativeApp && !nativeAccessToken) {
        // Log out of the webview if the user has logged out on native.
        logout({ shouldRedirectToFrontPage: false })
        return
      }
      // Access token validation:
      // Runs every time the app is loaded if the user is already logged in.
      // Validates the existing access token and fetches latest user profile data
      fetchLoggedUser()
      if (authBody) {
        ampli.identify(authBody?.private.amplitudeId, {
          landlord: authBody?.landlord,
          tenant: authBody?.tenant,
        })
        sentryIdentify({ id: authBody.uid, email: authBody.private.email })
      }

      setSignInCode(null)
    }

    // Sign in code exchange:
    // Runs if the app is loaded with a sign in code query param (assigned by BE e.g. when coming back from external id verification integration)
    // Works same as otp-login by exchanging the sign in code for an access token and fetching user data in the background
    else if (hasSignInCode) {
      exchangeSignInCodeForTokenAndFetchUser(signInCode!)
      setSignInCode(null)
    }
  })

  useEffect(() => {
    if (queryData?.me) {
      handleSetAuthBody(queryData.me)
      gtmCall({ event: 'USER_SET', payload: { schibstedAccountId: queryData.me.private.schibstedAccountId } })
    } else if (queryData?.me === null || error) {
      // if the query returned null, it means that there is no logged user with that token.
      logout({ shouldRedirectToFrontPage: false })
    }
  }, [queryData, error]) // eslint-disable-line react-hooks/exhaustive-deps

  const setAccessTokenAndFetchUser = useCallback(
    (accessToken: string) => {
      setP1AccessToken(accessToken)
      return fetchLoggedUser()
    },
    [fetchLoggedUser],
  )

  const handleSetAuthBody = useCallback(
    (newAuthBody: NonNullable<AuthBodyType>) => {
      ampli.identify(newAuthBody?.private.amplitudeId, {
        landlord: newAuthBody?.landlord,
        tenant: newAuthBody?.tenant,
        platform: 'web',
      })
      sentryIdentify({ id: newAuthBody.uid, email: newAuthBody.private.email })
      setAuthBody((oldAuthBody) => ({ ...oldAuthBody, ...newAuthBody }))
      setInitializing(false)
    },
    [setAuthBody],
  )

  const exchangeSignInCodeForTokenAndFetchUser = useCallback(
    async (signInCode: string) => {
      const { data } = await exchangeSignInCode({ code: signInCode! })
      if (data?.exchangeSignInCode?.accessToken) {
        await setAccessTokenAndFetchUser(data.exchangeSignInCode.accessToken)
      } else {
        // we could not get the access token, so we logout the user
        logout({ shouldRedirectToFrontPage: false })
      }
    },
    [exchangeSignInCode, logout, setAccessTokenAndFetchUser],
  )

  // NOTE: using fetchPolicy to make sure that the fetch is actually triggered - Neza 4.1.2023
  const refreshAuthBody = async () => {
    if (!isAuthenticated) return null
    return fetchLoggedUser({ fetchPolicy: 'cache-and-network' })
  }

  const value = {
    authBody,
    hasRedirectedViaLogout,
    isAuthenticated,
    isLoading: isInitializing,
    logout,
    refreshAuthBody,
    setAccessTokenAndFetchUser,
  }

  return (
    <AuthContext.Provider value={value}>
      <CurrentUserProvider
        currentUser={authBody}
        isAuthenticated={isAuthenticated}
        refetchCurrentUser={refreshAuthBody}
      >
        {children}
      </CurrentUserProvider>
    </AuthContext.Provider>
  )
}

const useAuthContext = () => useContext<AuthContextType>(AuthContext)

export { useAuthContext, AuthProvider }
